<!-- 添加回访弹窗 -->
<template>
  <div>
    <el-dialog
        width="50%"
        center
        :visible="visible"
        :lock-scroll="false"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'添加回访':'添加回访'"
        @update:visible="updateVisible">

      <div slot="title">
        <div style="display: flex;align-items: center;">
          <el-button type="primary" size="small" @click="openScript()">回访话术</el-button>
          <div class="tianjiagenjin">
            {{isUpdate?'添加回访':'添加回访'}}
          </div>
        </div>
      </div>

      <el-form
          ref="form"
          :model="form"
          label-width="80px">
        <el-row>
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">回访电话</span>
          </div>
        </el-row>
        <el-row :gutter="15">
          <div style="display: flex;align-items: center;">
            <div class="xuanxianglist">
              <!--<div v-for="(item,index) in list" :key="index" class="kuai">-->
              <!--  <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">-->
              <!--    {{item.name}}-->
              <!--  </div>-->
              <!--</div>-->

              <div class="margin_zuoyou">
                <el-radio-group v-model="type" @change="open">
                  <el-radio :label="item.type" v-for="(item,index) in list">{{item.name}}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <!--根据回访满意度显示或隐藏-->
            <div v-if="form.satisficing == 3 || form.satisficing == 4 || form.satisficing == 5">
              <!--<el-popconfirm title="是否确定？">-->
              <!--  <el-button slot="reference">转回访处理</el-button>-->
              <!--</el-popconfirm>-->
            </div>
          </div>
        </el-row>

        <el-row style="margin-top: 20px;" v-if="isShowtousu == false">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">回访满意度</span>
          </div>
        </el-row>
        <el-row :gutter="15" v-if="isShowtousu == false">
          <div class="group" style="margin-top: 15px;">
            <el-radio-group v-model="form.satisficing">
              <div style="display: flex;">
                <el-col v-for="(item,index) in dict.type.satisfaction" :key="index">
                  <el-radio :label="parseInt(item.value)">{{item.label}}</el-radio>
                </el-col>
              </div>
              <!--<el-col :span="6">-->
              <!--  <el-radio :label="2">不满意</el-radio>-->
              <!--</el-col>-->
              <!--<el-col :span="6">-->
              <!--  <el-radio :label="3">有投诉意向</el-radio>-->
              <!--</el-col>-->
              <!--<el-col :span="6">-->
              <!--  <el-radio :label="4">客户已投诉</el-radio>-->
              <!--</el-col>-->
            </el-radio-group>
          </div>
        </el-row>

        <el-row style="margin-top: 20px;">
          <div style="display: flex;align-items: center;">
            <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
            <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">
              <span v-if="isShowtousu == false">回访情况</span>
              <span v-else>投诉待处理</span>
            </span>
          </div>
        </el-row>
        <el-row v-if="isShowtousu == true" style="margin-top: 15px;">
          <el-form-item label="投诉原因" label-width="100px">
            <el-input
                placeholder="请输入内容"
                v-model="form.reason"
                clearable>
            </el-input>
            <!--<div style="margin-top: 10px;">-->
            <!--  <span style="font-size: 14px;font-weight: 500;color: #3f4157;margin-right: 10px;">快捷输入：</span>-->
            <!--    <span style="font-size: 14px;font-weight: 500;color: #FF9B05;margin-right: 10px; cursor: pointer;" v-for="(item,index) in fast" :key="index" @click="selectfast(item)">-->
            <!--    {{item.label}}-->
            <!--  </span>-->
            <!--</div>-->
          </el-form-item>
        </el-row>
        <el-row :gutter="15" style="margin-top: 10px;">
          <el-checkbox-group v-model="form.case" v-if="isShowtousu == false">
            <el-col :span="11" style="margin: 10px;" v-for="(item,index) in dict.type.condition" :key="index">
              <el-checkbox :label="item.label" :value="item.value"></el-checkbox>
            </el-col>
            <!--<el-col :span="11" style="margin: 10px;">-->
            <!--  <el-checkbox label="师傅已到达，对师傅有意见"></el-checkbox>-->
            <!--</el-col>-->
            <!--<el-col :span="11" style="margin: 10px;">-->
            <!--  <el-checkbox label="师傅未到达但已约好到达时间，同意给好评"></el-checkbox>-->
            <!--</el-col>-->
            <!--<el-col :span="11" style="margin: 10px;">-->
            <!--  <el-checkbox label="师傅未到达且不同意师傅预约时间"></el-checkbox>-->
            <!--</el-col>-->
            <!--<el-col :span="11" style="margin: 10px;">-->
            <!--  <el-checkbox label="未有师傅联系沟通达到时间"></el-checkbox>-->
            <!--</el-col>-->
            <!--<el-col :span="11" style="margin: 10px;">-->
            <!--  <el-checkbox label="有师傅联系但不同意师傅到达时间要求更换师傅"></el-checkbox>-->
            <!--</el-col>-->
          </el-checkbox-group>
          <el-checkbox-group v-model="form.case" v-if="isShowtousu == true">
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户同意撤销投诉"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户表示理解获取了客户原谅"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户车辆有车损 "></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户要求师傅致电道歉"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户对第一个师傅有意见，对第二个师傅给与好评"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="已给客户报销车辆损失费用"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="给客户报销取消费用"></el-checkbox>
            </el-col>
            <el-col :span="11" style="margin: 10px;">
              <el-checkbox label="客户自己找的救援师傅给客户报销 "></el-checkbox>
            </el-col>
          </el-checkbox-group>
        </el-row>

        <el-row style="margin-top: 10px; margin-bottom: 10px;" v-if="isShowtousu == false">
          很不满意 有投诉意向 客户已投诉(转异常 回访待处理)
        </el-row>

        <el-row style="margin-top: 10px; margin-bottom: 10px;" v-if="isShowtousu == false">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="输入回访情况"
              v-model="form.case_details">
          </el-input>
        </el-row>

        <div v-if="isShowtousu">
          <el-form-item label="相关图片" label-width="100px">
            <!--:class="{hide_box: upload_btn}"-->
            <el-upload
                :action="action"
                :headers="headers"
                :on-success="successUpload"
                :limit="1"
                :file-list="fileList"
                :on-change="change"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item label="投诉处理结果" label-width="100px">
            <el-input
                clearable
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                placeholder="请输入"
                v-model="form.details">
            </el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            v-if="form.satisficing != 0"
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
        <el-button style="margin-left: 20px;" type="info" v-else>确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="拨打电话"
        center
        :visible.sync="drawer"
        :show-close="false"
        width="30%"
        :before-close="handleClose">
      <div class="paddingbianju">
        <el-radio-group v-model="radio">
          <div class="xiabianju">
            <el-radio :label="1">小明 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="2">小红 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="3">小黑 13103000257</el-radio>
          </div>
        </el-radio-group>
      </div>
      <div slot="footer">
        <el-button class="rightmargin" @click="drawer = false">
          取消
        </el-button>
        <el-button type="primary" @click="queding" class="rightmargin">
          确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

import {Savevisit} from "@/api/orderModule";
// 引入的接口
import {get_tel_no} from '@/api/phone'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    //是否显示投诉内容
    isShowtousu: Boolean,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        odd:'',
        satisficing: 0,
        case:[],
        case_details:''
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //数据
      list:[
        {
          name:'拨打车主电话',
          type:'客户'
        },
        {
          name:'拨打其他电话',
          type:'其他'
        },
      ],
      isShow:0,
      type:'',

      radio:1,
      checkList:[],

      //快捷输入
      fast:[
        {
          label:'客户超时投诉'
        },
        {
          label: '客户对救援师傅态度不好投诉'
        }
      ],

      // 上传图片
      // logo
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      // 电话弹窗
      drawer:false,

      // // 是否显示投诉相关内容
      // isShowtousu:false

    };
  },
  watch: {

  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.odd = this.data.order_id;
          if(this.form.case_details == ''){
            this.$message.error('请输入原因')
          }else {
            Savevisit(this.data.id,this.form).then(res => {
              if(res.code == 200){
                this.loading = true;
                this.updateVisible(false);
                this.$emit('done');
                this.loading = false;
                this.$message.success(res.msg);
              }else {
                this.loading = false;
                this.$message.error(res.msg)
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg)
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },

    //快捷输入
    selectfast(item){
      console.log(item.label)
      this.form.reason += item.label
    },

    //logo
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.logo = file.data.urls;
    },


    open(){
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    queding(){
      this.tel();
    },

    // 电话话术
    openScript(){
      let showScript = true;
      this.$store.dispatch('theme/setshowScript', showScript).then(() => {
        console.log('打开成功')
        console.log(this.$store.state.theme.showScript,'全局话术')
      }).catch((e) => {
        console.error(e)
        this.$message.error('打开失败')
      })
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){
          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{
  .kuai{
    margin: 10px;
    display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .xuanxianglistactive:hover{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
  .xuanxianglistactive1{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
}


.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.tianjiagenjin{
  width: 670px;
  font-size: 18px;
}
.paddingbianju{
  //padding: 20px;
}

.phoneicon{
  width: 16px;height: 16px;margin-left: 10px;
}
.xiabianju{
  margin: 20px;
}
.rightmargin{
  margin-right: 20px;
}
.margin_zuoyou{
  margin-top: 15px;
  padding-left: 15px;
}
</style>
